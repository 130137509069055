import gql from "graphql-tag";

const INTEGRATION_STORE_WHATSAPP_CLOUD_API = gql`
  fragment integrationStoreWhatsappCloudApi on IntegrationWhatsappCloudApi {
    token
    phoneNumberId
    businessAccountId
    isEnabled
    templates {
      key
      title
      description
      selectedTemplate {
        id
        name
        language
      }
    }
  }
`;

export const GET_INTEGRATION_STORE_WHATSAPP_CLOUD_API = gql`
  query integrationStoreWhatsappCloudApi {
    integrationStoreWhatsappCloudApi {
      ...integrationStoreWhatsappCloudApi
    }
  }
  ${INTEGRATION_STORE_WHATSAPP_CLOUD_API}
`;
