<template>
  <div>
    <div class="vp-border vp-rounded-xl vp-p-6">
      <div class="vp-flex vp-gap-2 vp-items-center">
        <VpIcon
          :name="$options.icons.Whatsapp"
          class="vp-w-10 vp-h-10 vp-text-wa-green-500"
        />
        <VpField
          inline
          class="vp-grow"
          note=" Let your customers place an order quickly by sending a WhatsApp Message."
        >
          <template #label>
            <VpPageHeader title="Faster Checkout with WhatsApp" />
          </template>
          <VpSwitch
            id="whatsappChannelEnabled"
            :value="isWhatsappChannelEnabled"
            @input="$emit('update:isWhatsappChannelEnabled', $event)"
          />
        </VpField>
      </div>

      <div class="vp-mt-6 vp-grid vp-grid-cols-2 vp-gap-2 md:vp-grid-cols-3">
        <div v-for="(feature, index) in $options.features" :key="index">
          <div class="vp-flex vp-items-center vp-gap-2">
            <VpIcon
              :name="$options.icons.Check"
              class="vp-w-4 vp-h-4 vp-text-secondary-500"
            />
            <p class="vp-text-gray-500">{{ feature }}</p>
          </div>
        </div>
      </div>

      <div
        v-if="isWhatsappChannelEnabled"
        class="vp-mt-6 vp-grid vp-grid-cols-2 xl:vp-grid-cols-3 vp-gap-4"
      >
        <SelectCard
          class="vp-bg-white !vp-rounded-2xl"
          v-for="(option, index) in $options.options"
          :key="`select-card-${index}`"
          :active="option.value === channel"
          :title="option.title"
          @click.native="$emit('update:channel', option.value)"
          v-bind="option.props"
        >
          <div
            v-for="(feature, innerIndex) in option.features"
            :key="`select-card-feature-${innerIndex}`"
          >
            <div class="vp-flex vp-items-center vp-gap-2 vp-mt-2">
              <VpIcon
                v-if="feature.check"
                :name="$options.icons.Check"
                class="vp-w-5 vp-h-5 vp-text-success-500"
              />

              <VpIcon
                v-else
                :name="$options.icons.Close"
                class="vp-w-5 vp-h-5 vp-text-gray-500"
              />

              <p class="vp-leading-6">
                {{ feature.title }}
              </p>
            </div>
          </div>
        </SelectCard>
      </div>
    </div>

    <p
      v-if="channel === 'whatsapp_cloud_api'"
      class="vp-text-gray-500 vp-font-light vp-mt-4"
    >
      <b>Note:</b> To select your Whatsapp Cloud API Number option you need to
      set-up WhatsApp API integration.
      <RouterLink
        :to="{ name: 'whatsapp-cloud-api' }"
        class="vp-text-primary-500 vp-underline"
        >Set-Up Now</RouterLink
      >
    </p>
  </div>
</template>

<script>
// import PageHeader from "components/src/page-header.vue";
import { Whatsapp, Check, Close } from "icons/icons.js";
import SelectCard from "components/src/select-card.vue";

export default {
  icons: {
    Check,
    Whatsapp,
    Close,
  },
  components: {
    SelectCard,
  },
  props: {
    isWhatsappChannelEnabled: Boolean,
    channel: String,
  },
  features: [
    "No OTP Verification",
    "No Mobile No Input Required",
    "No Email Input Required",
    "Authentication with WhatsApp",
    "Pay Later Option",
    "Secure & Seamless",
  ],
  options: [
    {
      title: "Vepaar Official WhatsApp Number",
      value: "whatshash",
      props: {
        badge: "recommended",
        "badge-color": "success",
      },
      features: [
        {
          title: "Customer sends message to Vepaar’s WhatsApp Number.",
          check: true,
        },
        {
          title: "Track & Manage Orders in Vepaar",
          check: true,
        },
        {
          title: "Vepaar Credit Required",
          check: true,
        },
        {
          title: "Vepaar Branding",
          check: true,
        },
      ],
    },
    {
      title: "Your WhatsApp Number",
      value: "customer",
      features: [
        {
          title: "Customer sends message to Your WhatsApp Number.",
          check: true,
        },
        {
          title: "Track & Manage Orders in Vepaar",
          check: false,
        },
        {
          title: "Vepaar Credit Required",
          check: false,
        },
        {
          title: "Vepaar Branding",
          check: false,
        },
      ],
    },
    {
      title: "Your WhatsApp Cloud API Number",
      value: "whatsapp_cloud_api",
      features: [
        {
          title: "Customer sends message to Your WhatsApp API Number.",
          check: true,
        },
        {
          title: "Track & Manage Orders in Vepaar",
          check: true,
        },
        {
          title: "Vepaar Credit Required",
          check: false,
        },
        {
          title: "Vepaar Branding",
          check: false,
        },
      ],
    },
  ],
};
</script>
