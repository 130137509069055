<template>
  <VpVueForm
    id="true"
    :fields="fields"
    :form-state.sync="formState"
    class="vp-p-6"
    :get="get"
    width="400px"
    ref="item"
    :save="save"
    #default="{ state, response }"
    :track="$options.track"
    track-prefix="Integration"
    track-event-name="Configured"
    cache="integrationStoreWhatsappCloudApi"
    collection-cache="integrations"
  >
    <VpPageHeader title="Whatsapp Cloud API" />
    <VpStatusInput isEnum id="whatsapp-cloud-api" v-model="state.isEnabled" />

    <VpField rules="required" label="Token" name="Token">
      <VpInput>
        <VpTextBox v-model="state.token" />
      </VpInput>
    </VpField>

    <VpField rules="required" name="Phone Number ID" label="Phone Number ID">
      <VpInput>
        <VpTextBox v-model="state.phoneNumberId" />
      </VpInput>
    </VpField>

    <VpField
      rules="required"
      name="Business Account ID"
      label="Business Account ID"
    >
      <VpInput>
        <VpTextBox v-model="state.businessAccountId" />
      </VpInput>
    </VpField>

    <VpField
      v-if="response"
      label="Webhook Verification Token"
      name="Webhook Verification Token"
    >
      <div>
        <div class="vp-flex vp-gap-2 vp-items-center">
          <p
            class="vp-grow vp-border vp-border-gray-300 vp-rounded vp-p-4 vp-break-all"
          >
            Vepaar@metaapi
          </p>
          <VyButton
            :icon="$options.icons.Copy"
            v-tooltip="'Copy'"
            class="button--primary button--muted button--md button--rounded"
            @click.native="copyWebhook('Vepaar@metaapi')"
          />
        </div>
        <p class="vp-mt-2 text-warning">
          Place this Webhook Verification Token in your Account Configuration.
          Orders won't be placed via whatsApp if you've not setup this.
        </p>
      </div>
    </VpField>

    <VpField label="Webhook" name="Webhook">
      <div>
        <div class="vp-flex vp-gap-2 vp-items-center">
          <p
            class="vp-grow vp-border vp-border-gray-300 vp-rounded vp-p-4 vp-break-all"
          >
            {{ response.webhook }}
          </p>
          <VyButton
            :icon="$options.icons.Copy"
            v-tooltip="'Copy'"
            class="button--primary button--muted button--md button--rounded"
            @click.native="copyWebhook(response.webhook)"
          />
        </div>
        <p class="vp-mt-2 text-warning">
          Place this webhook in your Account Configuration. Orders won't be
          placed via whatsApp if you've not setup this.
        </p>
      </div>
    </VpField>

    <template v-if="response && response.token">
      <template v-for="template in response.templates">
        <VpField
          :key="`template--${template.key}`"
          :name="template.title"
          :label="template.title"
        >
          <template #label>
            <div class="vp-flex vp-items-center vp-gap-1">
              <label class="field__label"> {{ template.title }}</label>
              <VpIcon
                class="vp-text-gray-500"
                v-tooltip="template.description"
                :name="$options.icons.InfoOutline"
              />
            </div>
          </template>
          <VpInput>
            <template #default>
              <VpSelect
                :options="templates"
                :value="
                  state.templateConfig.find(
                    (stateTemplateConfig) =>
                      stateTemplateConfig.key == template.key
                  )?.value
                "
                @input="
                  ($event) => {
                    state.templateConfig.find(
                      (stateTemplateConfig) =>
                        stateTemplateConfig.key == template.key
                    ).value = $event;
                  }
                "
                placeholder="Select Template"
              ></VpSelect>
            </template>
          </VpInput>
        </VpField>
      </template>
    </template>
  </VpVueForm>
</template>
<script>
import {
  GET_INTEGRATION_STORE_WHATSAPP_CLOUD_API,
  UPSERT_INTEGRATION_STORE_WHATSAPP_CLOUD_API,
  GET_WHATSAPP_CLOUD_API_TEMPLATES,
} from "./gql";
import { InfoOutline, Copy } from "icons/icons.js";
export default {
  icons: {
    InfoOutline,
    Copy,
  },
  track: {
    trackingId: {
      key: "Provider",
      value: () => "WhatsApp Cloud API",
    },
  },
  created() {
    this.fetchTemplates();
  },
  data() {
    return {
      formState: {},
      templates: [],
      fields: [
        { name: "isEnabled", value: false },
        "token",
        "phoneNumberId",
        "businessAccountId",
        { name: "templateConfig", value: [] },
      ],
    };
  },
  methods: {
    copyWebhook(webhook) {
      this.$vayu.copy(webhook);
      this.$vayu.notify({
        title: "Copied",
      });
    },
    get() {
      return this.$query(GET_INTEGRATION_STORE_WHATSAPP_CLOUD_API).then(
        (res) => {
          try {
            if (res.data.integrationStoreWhatsappCloudApi) {
              res.data.integrationStoreWhatsappCloudApi.templates.forEach(
                (template) => {
                  this.formState.templateConfig.push({
                    key: template.key,
                    value: template.selectedTemplate
                      ? template.selectedTemplate.id
                      : null,
                  });
                }
              );
            }
          } catch (error) {
            console.log("🚀 ~ get ~ error:", error);
          }

          return {
            values: res.data.integrationStoreWhatsappCloudApi,
            res: res.data,
          };
        }
      );
    },
    fetchTemplates() {
      try {
        this.$query(
          GET_WHATSAPP_CLOUD_API_TEMPLATES,
          {},
          { noCache: true }
        ).then(({ data: { whatsappCloudApiTemplates } }) => {
          if (whatsappCloudApiTemplates) {
            this.templates = whatsappCloudApiTemplates.data.map((template) => {
              return {
                label: template.name,
                value: template.id,
              };
            });
          }
        });
      } catch (error) {
        console.error("Error fetching WhatsApp Cloud API Templates:", error);
      }
    },

    save(id, data) {
      if (data.templateConfig.length) {
        for (let template of data.templateConfig) {
          if (!template.value) {
            alert(`${template.key} template is required`);
            return new Promise((reject) => reject());
          }
        }
      }

      return this.$mutate(UPSERT_INTEGRATION_STORE_WHATSAPP_CLOUD_API, {
        input: {
          ...data,
        },
      }).then(({ data }) => {
        this.$cache.evict({
          id: "ROOT_QUERY",
          fieldName: "integrations",
        });

        this.$refs.item.refresh();
        this.fetchTemplates();
        return data;
      });
    },
  },
};
</script>
