import AddonsIndex from "@/views/settings/checkout/addons/Index.vue";
import AddonsList from "@/views/settings/checkout/addons/List.vue";
import AddonsItem from "@/views/settings/checkout/addons/Item.vue";
import MinOrder from "@/views/settings/checkout/min-order/index.vue";
import Form from "@/views/settings/checkout/form/index.vue";
import Settings from "@/views/settings/checkout/Settings.vue";
import UserVerification from "@/views/settings/checkout/user-verification/index.vue";
export default [
  {
    path: "addons",
    component: AddonsIndex,
    children: [
      {
        path: "",
        name: "checkout-addons",
        component: AddonsList,
        meta: {
          label: "Add-on",
          breadcrumbs: ["home", "settings", "checkout-addons"],
        },
      },
      {
        path: ":id",
        name: "checkout-addon",
        component: AddonsItem,
        props: true,
        meta: {
          label: "",
          breadcrumbs: [
            "home",

            "settings",

            "checkout-addons",
            "checkout-addon",
          ],
        },
      },
    ],
  },
  {
    path: "min-order",
    name: "checkout-min-order",
    component: MinOrder,
    meta: {
      label: "Minimum Order",
      breadcrumbs: ["home", "settings", "checkout-min-order"],
    },
  },

  {
    path: "form",
    name: "checkout-form",
    component: Form,
    meta: {
      label: "Address",
      breadcrumbs: ["home", "settings", "checkout-form"],
    },
  },
  {
    path: "note",
    name: "checkout-note",
    component: Settings,
    meta: {
      label: "Note",
      breadcrumbs: ["home", "settings", "checkout-note"],
    },
  },
  {
    path: "user-verification",
    name: "user-verification",
    component: UserVerification,
    meta: {
      label: "User Verification",
      breadcrumbs: ["home", "settings", "user-verification"],
    },
  },
];
