import gql from "graphql-tag";

const INTEGRATION_STORE_WHATSAPP_CLOUD_API = gql`
  fragment integrationStoreWhatsappCloudApi on IntegrationWhatsappCloudApi {
    token
    phoneNumberId
    businessAccountId
    webhook
    isEnabled
    templates {
      key
      title
      description
      selectedTemplate {
        id
        name
        language
      }
    }
  }
`;

export const GET_INTEGRATION_STORE_WHATSAPP_CLOUD_API = gql`
  query integrationStoreWhatsappCloudApi {
    integrationStoreWhatsappCloudApi {
      ...integrationStoreWhatsappCloudApi
    }
  }
  ${INTEGRATION_STORE_WHATSAPP_CLOUD_API}
`;

export const UPSERT_INTEGRATION_STORE_WHATSAPP_CLOUD_API = gql`
  mutation upsertStoreIntegrationWhatsappCloudApi(
    $input: IntegrationStoreWhatsappCloudApiInput!
  ) {
    upsertStoreIntegrationWhatsappCloudApi(input: $input) {
      ...integrationStoreWhatsappCloudApi
    }
  }
  ${INTEGRATION_STORE_WHATSAPP_CLOUD_API}
`;

const WHATSAPP_CLOUD_API_TEMPLATES = gql`
  fragment whatsappCloudApiTemplates on IntegrationWhatsappCloudApiTemplate {
    id
    name
    language
  }
`;

export const GET_WHATSAPP_CLOUD_API_TEMPLATES = gql`
  query whatsappCloudApiTemplates {
    whatsappCloudApiTemplates {
      data {
        ...whatsappCloudApiTemplates
      }
    }
  }
  ${WHATSAPP_CLOUD_API_TEMPLATES}
`;
