import gql from "graphql-tag";

export const CHECKOUT_VERIFICATION = gql`
  fragment checkoutVerification on CheckoutVerification {
    isWhatsappChannelEnabled
    channel
    whatsappCustomerNumber
    isMobileEnabled
    isOtpEnabled
  }
`;

export const GET_CHECKOUT_VERIFICATION = gql`
  query checkoutVerification {
    checkoutVerification {
      ...checkoutVerification
    }
  }
  ${CHECKOUT_VERIFICATION}
`;

export const UPSERT_CHECKOUT_VERIFICATION = gql`
  mutation upsertCheckoutVerification($input: CheckoutVerificationInput!) {
    upsertCheckoutVerification(input: $input) {
      ...checkoutVerification
    }
  }
  ${CHECKOUT_VERIFICATION}
`;
