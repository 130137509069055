<template>
  <VpVueForm
    :fields="fields"
    class="vp-p-6"
    id="true"
    :save="save"
    :get="get"
    #default="{ state }"
    :track="$options.track"
    ref="form"
    track-prefix="Checkout Mobile Number"
    title="Mobile Number"
    desc="Allow customers to checkout with their Mobile No."
    feature="CHECKOUT_SETTINGS"
    cache="checkoutVerification"
  >
    <VpStatusInput
      class="vp-max-w-sm"
      id="mobileEnabled"
      is-enum
      v-model="state.isMobileEnabled"
    />
    <VpStatusInput
      class="vp-max-w-sm"
      v-if="state.isMobileEnabled == true"
      id="otp-verification"
      is-enum
      label="OTP Verification"
      v-model="state.isOtpEnabled"
      desc="If enabled, we will send One Time Password on customer's WhatsApp to
        verify before placing an order."
    />
    <div class="vp-mt-6">
      <CheckoutWhatsapp
        :is-whatsapp-channel-enabled="state.isWhatsappChannelEnabled"
        :channel="state.channel"
        @update:isWhatsappChannelEnabled="
          state.isWhatsappChannelEnabled = $event
        "
        @update:channel="state.channel = $event"
      />
    </div>
    <VpField
      rules="required"
      class="vp-max-w-sm"
      v-if="state.isWhatsappChannelEnabled && state.channel === 'customer'"
      label="Your WhatsApp Number"
      note="Enter number with country code"
      name="WhatsApp Number"
    >
      <VpInput>
        <VpTextBox type="number" v-model="state.whatsappCustomerNumber" />
      </VpInput>
    </VpField>
  </VpVueForm>
</template>

<script>
import CheckoutWhatsapp from "./_whatsapp-checkout.vue";
import { GET_CHECKOUT_VERIFICATION, UPSERT_CHECKOUT_VERIFICATION } from "./gql";

export default {
  track: {
    status: "Status",
    isOtpEnabled: "OTP Verification",
  },
  components: {
    CheckoutWhatsapp,
  },
  data() {
    return {
      fields: [
        { name: "isMobileEnabled", value: false },
        { name: "isOtpEnabled", value: false },
        { name: "channel", value: "whatshash" },
        { name: "whatsappCustomerNumber", value: null },
        { name: "isWhatsappChannelEnabled", value: false },
      ],
    };
  },
  methods: {
    get() {
      return this.$query(GET_CHECKOUT_VERIFICATION).then(({ data }) => {
        return {
          values: data.checkoutVerification,
          res: data,
        };
      });
    },
    save(id, data) {
      if (!data.isMobileEnabled) {
        data.isOtpEnabled = false;
      }
      return this.$mutate(UPSERT_CHECKOUT_VERIFICATION, {
        input: {
          ...data,
        },
      }).then(({ data }) => data);
    },
  },
};
</script>
